.top-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: #323232;
    color: #fff;
    z-index: 1;
}
.top-header-in {
    max-width: 1640px;
    margin: auto;
}
.top-header-in img {
    height: 30px;
    padding: 8px 20px 2px 20px;
    display: inline-block;
    vertical-align: middle;
}
.top-header-in span {
    font-size: 20px;
    margin: 0px 5px 0 10px;
    display: inline-block;
    vertical-align: middle;
    line-height: 40px;
    font-weight: 600;
}
.top-header-in div.top-header-in-combo {
    position: relative;
    font-size: 20px;
    margin: 0px 5px 0 0px;
    display: inline-block;
    vertical-align: middle;
    line-height: 24px;
    font-weight: 600;
    cursor:pointer;
    padding: 0 10px;
}
.top-header-in div.top-header-in-combo:hover {
    color: #39f;
    width: 200px;
    /*background:#fff;*/
}
.top-header-in div.top-header-in-combo span {
    margin: 0;
    line-height: 30px;
}
svg.open-select {
    margin: 0;
    display: inline-block;
    cursor: pointer;
    width: 24px;
    height: 26px;
    fill: #fff;
    vertical-align: bottom;
}
.top-header-in div.top-header-in-combo:hover svg.open-select path {
    fill: #39f;
}
.top-header-in div.top-subcombo {
    display:none;
    color: #323232;
    background: #fff;
    position: absolute;
    width: 190px;
    padding: 0;
    top: 30px;
    left: -1px;
    border-bottom: 1px solid #323232;
    border-left: 1px solid #323232;
    border-right: 1px solid #323232;
}
.top-header-in div.top-header-in-combo:hover .top-subcombo {
    display:block;
}
.top-header-in div.top-subcombo span {
    font-size: 16px;
    width: calc(100% - 20px);
    padding: 0 10px;
}
.top-header-in div.top-subcombo span:hover {
    cursor: pointer;
    background: #e0e0e0;
}
.logged-as {
    display: block;
    line-height: 40px;
    font-size: 12px;
    margin: 0px 20px;
    float: right;
}
.logged-as span {
    font-size: 16px;
    cursor:pointer;
    margin-top: -5px;
    margin-left: 5px;
    margin-right: 0;
}
