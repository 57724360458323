.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eee;
  min-width: 700px;
}
.header-buttons {
padding: 10px 20px;
text-align: right;
margin: 40px auto 0px;
max-width: 1600px;
position: relative;
}
.button-add-contract {
background: #39f;
color:#fff;
padding:8px 20px;
border-radius:0px;
cursor:pointer;
display:inline-block;
text-transform: uppercase;
box-shadow: 1px 1px 2px #333;
}
.button-add-contract:hover {
background: #29AF8E;
}
.form-search {
float:left;
text-align: left;
}
.form-search input {
width: 400px;
font-size: 16px;
padding: 5px 10px;
border-radius: 5px;
outline: none;
}
.total-results-search {
font-size: 15px;
margin-left: 10px;
font-weight: bold;
}
.content-resultrows {
  position: absolute;
  top: 136px;
  left: 50%;
  height: calc(100% - 136px);;
  width: 100%;
  max-width: 1600px;
  transform: translateX(-50%);
  overflow: auto;
  scrollbar-color: #888 #ddd;
  scrollbar-width: thin;
}
@media(max-width: 950px) {
.form-search input {
  width: 200px;
  font-size: 14px;
}
.total-results-search {
  font-size: 13px;
}
.button-add-contract {
  font-size: 13px;
  padding: 8px 15px;
}
}
@media(max-width: 700px) {
  .App {height: auto;}
  .content-resultrows {height: auto;}
  .resultsrow-title.link {width: 80px;}
  .borrar-contrat-in-buttons {white-space: nowrap;}
}
.content-resultrows::-webkit-scrollbar {
  width: 8px;
}
.content-resultrows::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.content-resultrows::-webkit-scrollbar-track {
  background: #ddd;
}