header.previewshow {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 48px!important;
    background-color: #000016;
    text-align: left;
    position: fixed!important;
}
header.previewshow > .width {
    max-width: 1800px;
    margin: auto;
    position: relative;
}
header.previewshow > .width > img {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    margin: 13px 0 9px 12px;
    width: 104px;
    fill: #fefefe;
}
header.previewshow > .width > hr {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    width: 2px;
    background-color: #3399ff;
    border: none;
    margin: 0px 14px;
}
header.previewshow > .width > a.posttitle {
    color: #ffffff;
    text-decoration: none;
}
header.previewshow > .width > .ref_contrato {
    position: absolute;
    right: 12px;
    top: 0;
    margin: 13px 0;
    color: #fefefe;
}
header.previewshow .ref_contrato{
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    color: #fefefe;
    margin: 12px;
}
main.previewshow {
    margin: 0;
    padding: 64px 12px 16px 12px;
    min-height: calc(100% - 130px);
}
main.previewshow > .width {
    max-width: 1800px;
    margin: auto;
}
main.previewshow h1{
    font-size: 24px;
    line-height: 32px;
    margin: 16px 0;
    text-transform: uppercase;
}
main.previewshow h3{
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0;
}
main.previewshow img {
    max-width: 100%;
    height: auto;
    margin: auto;
}
main.previewshow table,
main.previewshow table tr,
main.previewshow table td,
main.previewshow table th {
    border: 1px solid #000016;
    border-collapse: collapse;
    padding: 5px;
}
footer.previewshow {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    background-color: #000016;
    text-align: left;
    position: static;
    bottom: 0;
    left: 0;
}
footer.previewshow > .width {
    max-width: 1800px;
    margin: auto;
    position: relative;
}
footer.previewshow > .width > img {
    padding: 0;
    margin: 0px 0 0px 12px;
    width: 104px;
    fill: #fefefe;
    vertical-align: middle;
    display: inline-block;
}
footer.previewshow > .width > p.copyright {
    color: #9c9c9c;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 20px;
    font-size: 14px;
    line-height: 50px;
}